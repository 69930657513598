.vertical-rhythm {
  padding-block: 0;
}

.vertical-rhythm--vertical-padding-xs {
  padding-block: var(--padding-xs);
}

.vertical-rhythm--vertical-padding-sm {
  padding-block: var(--padding-s);
}

.vertical-rhythm--vertical-padding-md {
  padding-block: var(--padding-m);
}

.vertical-rhythm--vertical-padding-lg {
  padding-block: var(--padding-l);
}

.vertical-rhythm--vertical-padding-xl {
  padding-block: var(--padding-xl);
}

.vertical-rhythm--vertical-padding-2xl {
  padding-block: var(--padding-2xl);
}

.vertical-rhythm__inner {
  display: flex;
  flex-direction: column;
}

.vertical-rhythm__inner--gap-xs {
  gap: var(--space-between-xs);
}

.vertical-rhythm__inner--gap-sm {
  gap: var(--space-between-s);
}

.vertical-rhythm__inner--gap-md {
  gap: var(--space-between-m);
}

.vertical-rhythm__inner--gap-lg {
  gap: var(--space-between-l);
}

.vertical-rhythm__inner--gap-xl {
  gap: var(--space-between-xl);
}

.vertical-rhythm__inner--gap-2xl {
  gap: var(--space-between-2xl);
}
