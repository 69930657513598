.youtube-player lite-youtube {
  max-width: unset;
}

.youtube-player button {
  filter: unset !important;
}

.youtube-player--aspect-ratio-1-1 {
  aspect-ratio: 1/1;
}

.youtube-player--aspect-ratio-4-3 {
  aspect-ratio: 4/3;
}

.youtube-player--aspect-ratio-16-9 {
  aspect-ratio: 16/9;
}
